
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































































































































































































.mb-preview {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  /* stylelint-disable-next-line unit-no-unknown */
  min-height: 100svh;
}

.mb-preview__panel {
  ::v-deep .mb-panel__content {
    justify-content: center;
  }
}

.mb-preview__panel__content {
  padding-inline: 4rem;
  text-align: center;

  @include mq(m) {
    max-width: 49.2rem;
    margin-inline: auto;
    padding-inline: 0;
  }
}

.overview__text {
  font-family: $ff-default;
  font-style: italic;
}
